<template>
  <!-- Country -->
  <div>
    <vs-select class="mb-3 w-full" autocomplete :label="$t('Country')" @change="onCountryChange" v-model="dataCountry">
        <vs-select-item value="AF" :text="'Afghanistan'"></vs-select-item>
        <vs-select-item value="AL" :text="'Albania'"></vs-select-item>
        <vs-select-item value="DZ" :text="'Algeria'"></vs-select-item>
        <vs-select-item value="AD" :text="'Andorra'"></vs-select-item>
        <vs-select-item value="AO" :text="'Angola'"></vs-select-item>
        <vs-select-item value="AI" :text="'Anguilla'"></vs-select-item>
        <vs-select-item value="AG" :text="'Antigua & Barbuda'"></vs-select-item>
        <vs-select-item value="AR" :text="'Argentina'"></vs-select-item>
        <vs-select-item value="AM" :text="'Armenia'"></vs-select-item>
        <vs-select-item value="AW" :text="'Aruba'"></vs-select-item>
        <vs-select-item value="AU" :text="'Australia'"></vs-select-item>
        <vs-select-item value="AT" :text="'Austria'"></vs-select-item>
        <vs-select-item value="AZ" :text="'Azerbaijan'"></vs-select-item>
        <vs-select-item value="BS" :text="'Bahamas'"></vs-select-item>
        <vs-select-item value="BH" :text="'Bahrain'"></vs-select-item>
        <vs-select-item value="BD" :text="'Bangladesh'"></vs-select-item>
        <vs-select-item value="BB" :text="'Barbados'"></vs-select-item>
        <vs-select-item value="BY" :text="'Belarus'"></vs-select-item>
        <vs-select-item value="BE" :text="'Belgium'"></vs-select-item>
        <vs-select-item value="BZ" :text="'Belize'"></vs-select-item>
        <vs-select-item value="BJ" :text="'Benin'"></vs-select-item>
        <vs-select-item value="BM" :text="'Bermuda'"></vs-select-item>
        <vs-select-item value="BT" :text="'Bhutan'"></vs-select-item>
        <vs-select-item value="BO" :text="'Bolivia'"></vs-select-item>
        <vs-select-item value="BA" :text="'Bosnia & Herzegovina'"></vs-select-item>
        <vs-select-item value="BW" :text="'Botswana'"></vs-select-item>
        <vs-select-item value="BR" :text="'Brazil'"></vs-select-item>
        <vs-select-item value="GB" :text="'Britain (UK)'"></vs-select-item>
        <vs-select-item value="IO" :text="'British Indian Ocean Territory'"></vs-select-item>
        <vs-select-item value="BN" :text="'Brunei'"></vs-select-item>
        <vs-select-item value="BG" :text="'Bulgaria'"></vs-select-item>
        <vs-select-item value="BF" :text="'Burkina Faso'"></vs-select-item>
        <vs-select-item value="BI" :text="'Burundi'"></vs-select-item>
        <vs-select-item value="KH" :text="'Cambodia'"></vs-select-item>
        <vs-select-item value="CM" :text="'Cameroon'"></vs-select-item>
        <vs-select-item value="CA" :text="'Canada'"></vs-select-item>
        <vs-select-item value="CV" :text="'Cape Verde'"></vs-select-item>
        <vs-select-item value="BQ" :text="'Caribbean NL'"></vs-select-item>
        <vs-select-item value="KY" :text="'Cayman Islands'"></vs-select-item>
        <vs-select-item value="CF" :text="'Central African Rep.'"></vs-select-item>
        <vs-select-item value="TD" :text="'Chad'"></vs-select-item>
        <vs-select-item value="CL" :text="'Chile'"></vs-select-item>
        <vs-select-item value="CN" :text="'China'"></vs-select-item>
        <vs-select-item value="CX" :text="'Christmas Island'"></vs-select-item>
        <vs-select-item value="CC" :text="'Cocos (Keeling) Islands'"></vs-select-item>
        <vs-select-item value="CO" :text="'Colombia'"></vs-select-item>
        <vs-select-item value="KM" :text="'Comoros'"></vs-select-item>
        <vs-select-item value="CD" :text="'Congo (Dem. Rep.)'"></vs-select-item>
        <vs-select-item value="CG" :text="'Congo (Rep.)'"></vs-select-item>
        <vs-select-item value="CK" :text="'Cook Islands'"></vs-select-item>
        <vs-select-item value="CR" :text="'Costa Rica'"></vs-select-item>
        <vs-select-item value="HR" :text="'Croatia'"></vs-select-item>
        <vs-select-item value="CU" :text="'Cuba'"></vs-select-item>
        <vs-select-item value="CW" :text="'Curacao'"></vs-select-item>
        <vs-select-item value="CY" :text="'Cyprus'"></vs-select-item>
        <vs-select-item value="CZ" :text="'Czech Republic'"></vs-select-item>
        <vs-select-item value="CI" :text="'Côte d\'Ivoire'"></vs-select-item>
        <vs-select-item value="DK" :text="'Denmark'"></vs-select-item>
        <vs-select-item value="DJ" :text="'Djibouti'"></vs-select-item>
        <vs-select-item value="DM" :text="'Dominica'"></vs-select-item>
        <vs-select-item value="DO" :text="'Dominican Republic'"></vs-select-item>
        <vs-select-item value="TL" :text="'East Timor'"></vs-select-item>
        <vs-select-item value="EC" :text="'Ecuador'"></vs-select-item>
        <vs-select-item value="EG" :text="'Egypt'"></vs-select-item>
        <vs-select-item value="SV" :text="'El Salvador'"></vs-select-item>
        <vs-select-item value="GQ" :text="'Equatorial Guinea'"></vs-select-item>
        <vs-select-item value="ER" :text="'Eritrea'"></vs-select-item>
        <vs-select-item value="EE" :text="'Estonia'"></vs-select-item>
        <vs-select-item value="ET" :text="'Ethiopia'"></vs-select-item>
        <vs-select-item value="FK" :text="'Falkland Islands'"></vs-select-item>
        <vs-select-item value="FO" :text="'Faroe Islands'"></vs-select-item>
        <vs-select-item value="FJ" :text="'Fiji'"></vs-select-item>
        <vs-select-item value="FI" :text="'Finland'"></vs-select-item>
        <vs-select-item value="FR" :text="'France'"></vs-select-item>
        <vs-select-item value="GF" :text="'French Guiana'"></vs-select-item>
        <vs-select-item value="PF" :text="'French Polynesia'"></vs-select-item>
        <vs-select-item value="GA" :text="'Gabon'"></vs-select-item>
        <vs-select-item value="GM" :text="'Gambia'"></vs-select-item>
        <vs-select-item value="GE" :text="'Georgia'"></vs-select-item>
        <vs-select-item value="DE" :text="'Germany'"></vs-select-item>
        <vs-select-item value="GH" :text="'Ghana'"></vs-select-item>
        <vs-select-item value="GI" :text="'Gibraltar'"></vs-select-item>
        <vs-select-item value="GR" :text="'Greece'"></vs-select-item>
        <vs-select-item value="GL" :text="'Greenland'"></vs-select-item>
        <vs-select-item value="GD" :text="'Grenada'"></vs-select-item>
        <vs-select-item value="GP" :text="'Guadeloupe'"></vs-select-item>
        <vs-select-item value="GU" :text="'Guam'"></vs-select-item>
        <vs-select-item value="GT" :text="'Guatemala'"></vs-select-item>
        <vs-select-item value="GG" :text="'Guernsey'"></vs-select-item>
        <vs-select-item value="GN" :text="'Guinea'"></vs-select-item>
        <vs-select-item value="GW" :text="'Guinea-Bissau'"></vs-select-item>
        <vs-select-item value="GY" :text="'Guyana'"></vs-select-item>
        <vs-select-item value="HT" :text="'Haiti'"></vs-select-item>
        <vs-select-item value="HN" :text="'Honduras'"></vs-select-item>
        <vs-select-item value="HK" :text="'Hong Kong'"></vs-select-item>
        <vs-select-item value="HU" :text="'Hungary'"></vs-select-item>
        <vs-select-item value="IS" :text="'Iceland'"></vs-select-item>
        <vs-select-item value="IN" :text="'India'"></vs-select-item>
        <vs-select-item value="ID" :text="'Indonesia'"></vs-select-item>
        <vs-select-item value="IR" :text="'Iran'"></vs-select-item>
        <vs-select-item value="IQ" :text="'Iraq'"></vs-select-item>
        <vs-select-item value="IE" :text="'Ireland'"></vs-select-item>
        <vs-select-item value="IM" :text="'Isle of Man'"></vs-select-item>
        <vs-select-item value="IL" :text="'Israel'"></vs-select-item>
        <vs-select-item value="IT" :text="'Italy'"></vs-select-item>
        <vs-select-item value="JM" :text="'Jamaica'"></vs-select-item>
        <vs-select-item value="JP" :text="'Japan'"></vs-select-item>
        <vs-select-item value="JE" :text="'Jersey'"></vs-select-item>
        <vs-select-item value="JO" :text="'Jordan'"></vs-select-item>
        <vs-select-item value="KZ" :text="'Kazakhstan'"></vs-select-item>
        <vs-select-item value="KE" :text="'Kenya'"></vs-select-item>
        <vs-select-item value="KI" :text="'Kiribati'"></vs-select-item>
        <vs-select-item value="KP" :text="'Korea (North)'"></vs-select-item>
        <vs-select-item value="KR" :text="'Korea (South)'"></vs-select-item>
        <vs-select-item value="KW" :text="'Kuwait'"></vs-select-item>
        <vs-select-item value="KG" :text="'Kyrgyzstan'"></vs-select-item>
        <vs-select-item value="LA" :text="'Laos'"></vs-select-item>
        <vs-select-item value="LV" :text="'Latvia'"></vs-select-item>
        <vs-select-item value="LB" :text="'Lebanon'"></vs-select-item>
        <vs-select-item value="LS" :text="'Lesotho'"></vs-select-item>
        <vs-select-item value="LR" :text="'Liberia'"></vs-select-item>
        <vs-select-item value="LY" :text="'Libya'"></vs-select-item>
        <vs-select-item value="LI" :text="'Liechtenstein'"></vs-select-item>
        <vs-select-item value="LT" :text="'Lithuania'"></vs-select-item>
        <vs-select-item value="LU" :text="'Luxembourg'"></vs-select-item>
        <vs-select-item value="MO" :text="'Macau'"></vs-select-item>
        <vs-select-item value="MK" :text="'Macedonia'"></vs-select-item>
        <vs-select-item value="MG" :text="'Madagascar'"></vs-select-item>
        <vs-select-item value="MW" :text="'Malawi'"></vs-select-item>
        <vs-select-item value="MY" :text="'Malaysia'"></vs-select-item>
        <vs-select-item value="MV" :text="'Maldives'"></vs-select-item>
        <vs-select-item value="ML" :text="'Mali'"></vs-select-item>
        <vs-select-item value="MT" :text="'Malta'"></vs-select-item>
        <vs-select-item value="MH" :text="'Marshall Islands'"></vs-select-item>
        <vs-select-item value="MQ" :text="'Martinique'"></vs-select-item>
        <vs-select-item value="MR" :text="'Mauritania'"></vs-select-item>
        <vs-select-item value="MU" :text="'Mauritius'"></vs-select-item>
        <vs-select-item value="YT" :text="'Mayotte'"></vs-select-item>
        <vs-select-item value="MX" :text="'Mexico'"></vs-select-item>
        <vs-select-item value="FM" :text="'Micronesia'"></vs-select-item>
        <vs-select-item value="MD" :text="'Moldova'"></vs-select-item>
        <vs-select-item value="MC" :text="'Monaco'"></vs-select-item>
        <vs-select-item value="MN" :text="'Mongolia'"></vs-select-item>
        <vs-select-item value="ME" :text="'Montenegro'"></vs-select-item>
        <vs-select-item value="MS" :text="'Montserrat'"></vs-select-item>
        <vs-select-item value="MA" :text="'Morocco'"></vs-select-item>
        <vs-select-item value="MZ" :text="'Mozambique'"></vs-select-item>
        <vs-select-item value="MM" :text="'Myanmar (Burma)'"></vs-select-item>
        <vs-select-item value="NA" :text="'Namibia'"></vs-select-item>
        <vs-select-item value="NR" :text="'Nauru'"></vs-select-item>
        <vs-select-item value="NP" :text="'Nepal'"></vs-select-item>
        <vs-select-item value="NL" :text="'Netherlands'"></vs-select-item>
        <vs-select-item value="NC" :text="'New Caledonia'"></vs-select-item>
        <vs-select-item value="NZ" :text="'New Zealand'"></vs-select-item>
        <vs-select-item value="NI" :text="'Nicaragua'"></vs-select-item>
        <vs-select-item value="NE" :text="'Niger'"></vs-select-item>
        <vs-select-item value="NG" :text="'Nigeria'"></vs-select-item>
        <vs-select-item value="NU" :text="'Niue'"></vs-select-item>
        <vs-select-item value="NF" :text="'Norfolk Island'"></vs-select-item>
        <vs-select-item value="MP" :text="'Northern Mariana Islands'"></vs-select-item>
        <vs-select-item value="NO" :text="'Norway'"></vs-select-item>
        <vs-select-item value="OM" :text="'Oman'"></vs-select-item>
        <vs-select-item value="PK" :text="'Pakistan'"></vs-select-item>
        <vs-select-item value="PW" :text="'Palau'"></vs-select-item>
        <vs-select-item value="PS" :text="'Palestine'"></vs-select-item>
        <vs-select-item value="PA" :text="'Panama'"></vs-select-item>
        <vs-select-item value="PG" :text="'Papua New Guinea'"></vs-select-item>
        <vs-select-item value="PY" :text="'Paraguay'"></vs-select-item>
        <vs-select-item value="PE" :text="'Peru'"></vs-select-item>
        <vs-select-item value="PH" :text="'Philippines'"></vs-select-item>
        <vs-select-item value="PN" :text="'Pitcairn'"></vs-select-item>
        <vs-select-item value="PL" :text="'Poland'"></vs-select-item>
        <vs-select-item value="PT" :text="'Portugal'"></vs-select-item>
        <vs-select-item value="PR" :text="'Puerto Rico'"></vs-select-item>
        <vs-select-item value="QA" :text="'Qatar'"></vs-select-item>
        <vs-select-item value="RO" :text="'Romania'"></vs-select-item>
        <vs-select-item value="RU" :text="'Russia'"></vs-select-item>
        <vs-select-item value="RW" :text="'Rwanda'"></vs-select-item>
        <vs-select-item value="RE" :text="'Réunion'"></vs-select-item>
        <vs-select-item value="AS" :text="'Samoa (American)'"></vs-select-item>
        <vs-select-item value="WS" :text="'Samoa (western)'"></vs-select-item>
        <vs-select-item value="SM" :text="'San Marino'"></vs-select-item>
        <vs-select-item value="ST" :text="'Sao Tome & Principe'"></vs-select-item>
        <vs-select-item value="SA" :text="'Saudi Arabia'"></vs-select-item>
        <vs-select-item value="SN" :text="'Senegal'"></vs-select-item>
        <vs-select-item value="RS" :text="'Serbia'"></vs-select-item>
        <vs-select-item value="SC" :text="'Seychelles'"></vs-select-item>
        <vs-select-item value="SL" :text="'Sierra Leone'"></vs-select-item>
        <vs-select-item value="SG" :text="'Singapore'"></vs-select-item>
        <vs-select-item value="SK" :text="'Slovakia'"></vs-select-item>
        <vs-select-item value="SI" :text="'Slovenia'"></vs-select-item>
        <vs-select-item value="SB" :text="'Solomon Islands'"></vs-select-item>
        <vs-select-item value="SO" :text="'Somalia'"></vs-select-item>
        <vs-select-item value="ZA" :text="'South Africa'"></vs-select-item>
        <vs-select-item value="SS" :text="'South Sudan'"></vs-select-item>
        <vs-select-item value="ES" :text="'Spain'"></vs-select-item>
        <vs-select-item value="LK" :text="'Sri Lanka'"></vs-select-item>
        <vs-select-item value="BL" :text="'St Barthelemy'"></vs-select-item>
        <vs-select-item value="SH" :text="'St Helena'"></vs-select-item>
        <vs-select-item value="KN" :text="'St Kitts & Nevis'"></vs-select-item>
        <vs-select-item value="LC" :text="'St Lucia'"></vs-select-item>
        <vs-select-item value="SX" :text="'St Maarten (Dutch)'"></vs-select-item>
        <vs-select-item value="MF" :text="'St Martin (French)'"></vs-select-item>
        <vs-select-item value="PM" :text="'St Pierre & Miquelon'"></vs-select-item>
        <vs-select-item value="VC" :text="'St Vincent'"></vs-select-item>
        <vs-select-item value="SD" :text="'Sudan'"></vs-select-item>
        <vs-select-item value="SR" :text="'Suriname'"></vs-select-item>
        <vs-select-item value="SJ" :text="'Svalbard & Jan Mayen'"></vs-select-item>
        <vs-select-item value="SZ" :text="'Swaziland'"></vs-select-item>
        <vs-select-item value="SE" :text="'Sweden'"></vs-select-item>
        <vs-select-item value="CH" :text="'Switzerland'"></vs-select-item>
        <vs-select-item value="SY" :text="'Syria'"></vs-select-item>
        <vs-select-item value="TW" :text="'Taiwan'"></vs-select-item>
        <vs-select-item value="TJ" :text="'Tajikistan'"></vs-select-item>
        <vs-select-item value="TZ" :text="'Tanzania'"></vs-select-item>
        <vs-select-item value="TH" :text="'Thailand'"></vs-select-item>
        <vs-select-item value="TG" :text="'Togo'"></vs-select-item>
        <vs-select-item value="TK" :text="'Tokelau'"></vs-select-item>
        <vs-select-item value="TO" :text="'Tonga'"></vs-select-item>
        <vs-select-item value="TT" :text="'Trinidad & Tobago'"></vs-select-item>
        <vs-select-item value="TN" :text="'Tunisia'"></vs-select-item>
        <vs-select-item value="TR" :text="'Turkey'"></vs-select-item>
        <vs-select-item value="TM" :text="'Turkmenistan'"></vs-select-item>
        <vs-select-item value="TC" :text="'Turks & Caicos Is'"></vs-select-item>
        <vs-select-item value="TV" :text="'Tuvalu'"></vs-select-item>
        <vs-select-item value="UM" :text="'US minor outlying islands'"></vs-select-item>
        <vs-select-item value="UG" :text="'Uganda'"></vs-select-item>
        <vs-select-item value="UA" :text="'Ukraine'"></vs-select-item>
        <vs-select-item value="AE" :text="'United Arab Emirates'"></vs-select-item>
        <vs-select-item value="US" :text="'United States'"></vs-select-item>
        <vs-select-item value="UY" :text="'Uruguay'"></vs-select-item>
        <vs-select-item value="UZ" :text="'Uzbekistan'"></vs-select-item>
        <vs-select-item value="VU" :text="'Vanuatu'"></vs-select-item>
        <vs-select-item value="VA" :text="'Vatican City'"></vs-select-item>
        <vs-select-item value="VE" :text="'Venezuela'"></vs-select-item>
        <vs-select-item value="VN" :text="'Vietnam'"></vs-select-item>
        <vs-select-item value="VG" :text="'Virgin Islands (UK)'"></vs-select-item>
        <vs-select-item value="VI" :text="'Virgin Islands (US)'"></vs-select-item>
        <vs-select-item value="WF" :text="'Wallis & Futuna'"></vs-select-item>
        <vs-select-item value="EH" :text="'Western Sahara'"></vs-select-item>
        <vs-select-item value="YE" :text="'Yemen'"></vs-select-item>
        <vs-select-item value="ZM" :text="'Zambia'"></vs-select-item>
        <vs-select-item value="ZW" :text="'Zimbabwe'"></vs-select-item>
        <vs-select-item value="AX" :text="'Åland Islands'"></vs-select-item>
    </vs-select>
    <!-- TimeZone -->
    <vs-select class="mb-3 w-full" :label="$t('Timezone')" @change="onTimezoneChange" v-model="dataTimezone">
      <vs-select-item :key="item.tzid" :value="item.tzid" :text="item.name" v-for="item in finalTimezones" />
    </vs-select>
  </div>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import {
  TIMEZONE_JSON,
} from '@/lib/statics';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Load plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      timezoneOptions: TIMEZONE_JSON,
      dataTimezone: '',
      dataCountry: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  watch: {
    dataTimezoneFromParent() {
      this.dataTimezone = this.dataTimezoneFromParent;
    },
    dataCountryFromParent() {
      this.dataCountry = this.dataCountryFromParent;
    },
  },
  props: {
    dataTimezoneFromParent: {
      type: String,
      default: () => '',
    },
    dataCountryFromParent: {
      type: String,
      default: () => '',
    },
    finalTimezones: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onCountryChange() {
      if (this.dataCountryFromParent !== this.dataCountry) this.dataTimezone = '';
      this.$emit('onCountryChange', this.dataCountry, this.dataTimezone);
    },
    onTimezoneChange() {
      this.$emit('onTimezoneChange', this.dataTimezone);
    },
  },
  created() {
  },
  mounted() {
    this.dataCountry = this.dataCountryFromParent;
    this.dataTimezone = this.dataTimezoneFromParent;
  },
};

</script>
<style>
</style>
