<template>
  <div v-if="!isFetching && !isFetchingCurrencies" class="user-settings ml-12">
    <div class="mb-4 mt-6" style="font-size: 28px!important">{{$t('Settings')}}</div>
    <h6>{{ $t('General Setings for all of your event') }}</h6>
    <!-- Localization-->
    <h5 class="mt-8">{{$t('Localization')}}</h5>
    <!-- <label class="text-grey">{{ $t('Set localization for your event') }}</label> -->
    <!-- Country -->
    <div class="vx-col mt-4 mb-2" style="width: 400px">
      <!-- timezone -->
      <div class="w-full">
        <Timezone
        :dataCountryFromParent="dataCountry"
        :dataTimezoneFromParent="dataTimezone"
        :finalTimezones="finalTimezones"
        @onCountryChange="onCountryChange"
        @onTimezoneChange="onTimezoneChange"></Timezone>
      </div>
      <!-- Language -->
      <label>{{ $t('Language') }}</label>
      <vs-select name="language-settings-user"  class="mb-3 w-full"
        v-model="dataLanguage">
        <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in languageOptions" />
      </vs-select>
    </div>
    <div>
      <vs-button @click="updateSettings()" color="#4690DE" class="vs-button-primary mt-10">
        {{$t('Save Changes')}}</vs-button>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import {
  TIMEZONE_JSON,
} from '@/lib/statics';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Timezone from '@/components/Timezone.vue';
import {
  getAxiosErrorMessage,
  duplicateVar,
} from '../lib/helper';
import authApi from '../api/auth';

const ct = require('countries-and-timezones');

// Load plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'Home',
  components: {
    Timezone,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      coverImage: require('@/assets/images/backgrounds/night_sky.jpg'),
      isFetching: false,
      orderBy: 'created_at',
      sortBy: 'desc',
      currencies: [],
      dataCountry: '',
      dataCurrency: '',
      dataLanguage: 'no',
      dataTimezone: '',
      isSaving: false,
      userProfile: [],
      countryOptions: [{
        value: 'Norway',
        text: 'Norway',
      },
      {
        value: 'US',
        text: 'United States',
      },
      ],
      languageOptions: [{
        value: 'no',
        text: 'Norsk',
      },
      {
        value: 'en',
        text: 'English',
      },
      ],
      timezoneOptions: TIMEZONE_JSON,
      finalTimezones: [],
      isFetchingCurrencies: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
    currenciesOptions() {
      const currencies = duplicateVar(this.currencies);
      return currencies;
    },
    userName() {
      const name = this.user ? `${this.user.name}` : 'Home';
      return name;
    },
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      document.querySelector('html').setAttribute('lang', locale);
      this.$store.dispatch('setLocale', locale);
    },
    updateSettings() {
      this.isSaving = true;
      const title = this.$t('User Settings');
      const callback = (response) => {
        const message = this.$t('Your changes have been saved.');
        this.$store.dispatch('setUser', response.data);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
        this.updateLocale(this.dataLanguage);
      };
      const errorCallback = () => {
        this.$vs.notify({
          title,
          text: 'Something when wrong',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };
      const userSettings = {
        country: this.dataCountry,
        timezone: this.dataTimezone,
        language: this.dataLanguage,
      };
      const paramsSettings = {
        settings: JSON.stringify(userSettings),
      };
      authApi.update(this.userProfile.id, paramsSettings, callback, errorCallback);
    },
    fetchUser() {
      this.$vs.loading();
      this.isFetching = true;
      const callback = async (response) => {
        const userSettings = JSON.parse(response.data.settings);
        if (userSettings && userSettings.timezone) {
          this.setUserSettings(userSettings);
        } else {
          await this.setDefaultCountry();
          this.updateSettings();
        }
        this.user = response.data;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
        this.$vs.loading.close();
      };
      authApi.getProfile(callback, errorCallback);
    },
    setUserSettings(userSettings) {
      if (userSettings) {
        const timezone = userSettings.timezone;
        const country = userSettings.country;
        const language = userSettings.language;
        this.dataCountry = country;
        this.dataLanguage = language;
        this.dataTimezone = timezone;
        this.setDefaultCountry();
      }
    },
    setDefaultCountry() {
      // Set the local timezone
      dayjs.tz.setDefault(dayjs.tz.guess());

      // Get the current timezone identifier
      let defaultTimezone = dayjs.tz.guess();
      if (this.dataTimezone) defaultTimezone = this.dataTimezone;
      const getCountryBytimezone = ct.getTimezone(defaultTimezone);

      if (getCountryBytimezone && getCountryBytimezone.countries && getCountryBytimezone.countries.length > 0) this.dataCountry = getCountryBytimezone.countries[0];

      if (this.timezoneOptions && this.timezoneOptions.length > 0 && !this.dataTimezone) {
        for (let index = 0; index < this.timezoneOptions[0][this.dataCountry].length; index++) {
          const timezoneObj = duplicateVar(this.timezoneOptions[0][this.dataCountry][index]);
          const now = dayjs(new Date());
          timezoneObj.name = duplicateVar(`(UTC${dayjs.tz(now, timezoneObj.tzid).format('Z')}) ${timezoneObj.tzid}`);
          this.finalTimezones.push(timezoneObj);
          this.dataTimezone = this.finalTimezones[0].tzid;
        }
      }
    },
    onTimezoneChange(timezone) {
      this.dataTimezone = timezone;
    },
    onCountryChange(country, timezone) {
      this.dataTimezone = '';
      this.finalTimezones = [];
      this.dataCountry = country;
      if (this.timezoneOptions && this.timezoneOptions.length > 0) {
        for (let index = 0; index < this.timezoneOptions[0][this.dataCountry].length; index++) {
          const timezoneObj = duplicateVar(this.timezoneOptions[0][this.dataCountry][index]);
          const now = dayjs(new Date());
          timezoneObj.name = duplicateVar(`(UTC${dayjs.tz(now, timezoneObj.tzid).format('Z')}) ${timezoneObj.tzid}`);
          this.finalTimezones.push(timezoneObj);
          this.dataTimezone = timezone || this.finalTimezones[0].tzid;
        }
      }
    },
  },
  created() {
    this.fetchUser();
  },
  mounted() {
  },
};

</script>
<style>
  .save-btn-settings {
    position: relative;
    bottom: 0;
  }

</style>
